<template>
  <div class="import-music">
    <van-nav-bar title="导入音乐">
      <template #left>
        <van-icon name="arrow-left" size="1.8rem" color="rgba(0, 0, 0, 0.8)" @click="goBack" />
      </template>

      <template #right>
        <van-icon name="replay" size="1.8rem" color="var(--primary-color)" @click="resetFile" />
      </template>
    </van-nav-bar>

    <!-- 添加提示语 -->
    <van-notice-bar
      left-icon="info-o"
      text="导入文件的数据格式可参考下载json文件查看当前播放列表数据格式。"
    />

    <!-- 表单信息 -->
    <van-form class="import-form" scroll-to-error @submit="onSubmit">
      <template v-if="!state.jsonFile" key="upload-file">
        <van-cell-group inset>
          <van-field input-align="center" clickable>
            <template #input>
              <!-- json文件上传 -->
              <van-uploader
                v-model="state.fileList"
                :before-read="fileBeforeRead"
                :after-read="fileAfterRead"
                :max-count="1"
                accept="application/json"
                result-type="file"
                upload-text="上传JSON"
                upload-icon="upgrade"
              >
                <template #default>
                  <div class="custom-upload-view">
                    <svg-icon class="upload-icon" name="icon-shangchuandaoyun" size="3rem" />
                    <span class="upload-text">上传JSON文件</span>
                  </div>
                </template>
              </van-uploader>
            </template>
          </van-field>
        </van-cell-group>
      </template>

      <template v-else key="check-list">
        <van-checkbox-group
          v-model="state.checkList"
          icon-size="2rem"
          checked-color="var(--primary-color)"
        >
          <van-cell-group v-if="state.jsonContent.length" inset>
            <van-cell
              v-for="(item, index) in state.jsonContent"
              clickable
              :key="`${item.id}-${index}`"
              :title="`${item.title} - ${item.author}${item.disabled ? '（已存在）' : ''}`"
              @click="toggleCheck(item, index)"
            >
              <template #right-icon>
                <van-checkbox
                  :name="`${item.title}`"
                  :ref="(el) => (state.checkboxRefs[index] = el)"
                  :disabled="item.disabled"
                  @click.stop
                />
              </template>
            </van-cell>
          </van-cell-group>

          <van-empty v-else description="读取列表为空" />
        </van-checkbox-group>
      </template>
      <div v-if="state.jsonContent.length" style="margin: 2rem">
        <van-button
          round
          block
          type="primary"
          :disabled="state.checkList.length === 0"
          native-type="submit"
          >提 交</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'
import {
  NavBar,
  Icon,
  Dialog,
  Notify,
  Form,
  Field,
  Cell,
  CellGroup,
  Button,
  NoticeBar,
  Uploader,
  Checkbox,
  CheckboxGroup,
  Empty
} from 'vant'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SvgIcon from '@/components/svg-icon'

export default defineComponent({
  name: 'import-music',

  components: {
    SvgIcon,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Empty.name]: Empty
  },

  setup (props, context) {
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      jsonFile: null,
      fileList: [],
      jsonContent: [],
      checkList: [],
      checkboxRefs: []
    })

    // 返回
    function goBack () {
      if (window.history.length <= 2) {
        router.replace('/')
      } else {
        router.back()
      }
    }

    // 重置上传文件处理
    function resetFile () {
      Dialog.confirm({
        message: '确认重置已上传的文件？'
      }).then(() => {
        state.jsonFile = null
        state.fileList = []
        state.jsonContent = []
        state.checkList = []
        state.checkboxRefs = []
        Notify({
          type: 'success',
          message: '已重置'
        })
      })
    }

    // 文件上传前 格式校验
    function fileBeforeRead (file) {
      if (file.type !== 'application/json') {
        Notify({
          type: 'warning',
          message: '请上传json格式文件'
        })
        return false
      }
      return true
    }

    // 文件读取后
    function fileAfterRead (content) {
      state.jsonFile = content.file
      const reader = new FileReader();//新建一个FileReader
      reader.readAsText(content.file, "UTF-8");//读取文件 
      reader.onload = function (evt) { //读取完文件之后会回来这里
        const fileText = evt.target.result
        try {
          const fileList = JSON.parse(fileText)
          state.jsonContent = fileList.map((item) => {
            const isDisabled = store.dispatch('validateMusicExit', item)
            if (!isDisabled) {
              state.checkList.push(title)
            }
            return {
              ...item,
              title,
              disabled: isDisabled
            }
          })
        } catch (error) {
          console.log(error);
          state.jsonContent = []
        }
      }
    }

    // 切换选中
    function toggleCheck (item, index) {
      if (!item.disabled) {
        state.checkboxRefs[index].toggle()
      }
    }

    // 表单提交, 批量添加
    function onSubmit () {
      const resList = state.jsonContent.filter(item => !item.disabled)
      store.dispatch('importMusicList', resList)
      goBack()
    }

    return {
      state,
      goBack,
      resetFile,
      fileBeforeRead,
      fileAfterRead,
      toggleCheck,
      onSubmit
    }
  }
})
</script>
<style lang="scss" scoped>
.import-music {
  .import-form {
    margin-top: 1.6rem;

    .custom-upload-view {
      padding: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.02);
      border-radius: 0.4rem;

      .upload-icon {
        margin-bottom: 1rem;
      }

      .upload-text {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
<style lang="scss">
.import-music {
  .import-form {
    .van-uploader,
    .van-uploader__input-wrapper {
      flex: 1;
    }
  }
}
</style>
